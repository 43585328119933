import React from 'react';
import PropTypes from 'prop-types';

import lobbyStyles from '../styles/lobby.module.sass';

import RNSButton from '../../common/js/components/rns-button';

const GoogleSignInButton = ({ token = '', text = 'Sign in with Google' }) => (
  <RNSButton
    className={lobbyStyles.googleSignInButton}
    type="button"
    handleClick={
      () => {
        window.location = `/auth/go/${token}`;
      }
    }
    prompt={(
      <div className={lobbyStyles.googleSignInButtonLabel}>
        <img src="/static/img/google-logo.svg" />
        <div>{text}</div>
      </div>
    )}
  />
);

GoogleSignInButton.propTypes = {
  token: PropTypes.string,
  text: PropTypes.string,
};

export default GoogleSignInButton;
