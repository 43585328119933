import React, { useEffect, useState } from 'react';
import axios from 'axios';

import RNSButton from '../../common/js/components/rns-button';
import Lobby from './lobby';

import { validateEmail } from '../../../modules/utils';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [linkSent, setLinkSent] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onSubmit = (e) => {
    e?.preventDefault();
    if (!validateEmail(email)) return;
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted && !linkSent) {
      axios
        .post('/forgot', { email })
        .catch();
      setLinkSent(true);
    }
  }, [submitted]);

  return (
    <Lobby
      id="forgot-password-view"
      header="Forgot your password?"
      subHeader={
        linkSent
          ? 'Password reset link has been sent to your email.'
          : 'Enter your email address and we\'ll send you a link to reset your password.'
      }
    >
      { linkSent ? null : (
        <form onSubmit={onSubmit}>
          <input
            id="reset-password-input"
            type="email"
            placeholder="your e-mail address"
            value={email}
            onChange={e => setEmail(e.target.value)}
            autoFocus
          />
          <div>
            <RNSButton
              id="reset-button"
              theme="core"
              disabled={!validateEmail(email)}
              processing={submitted}
              prompt="Send"
              handleClick={onSubmit}
            />
          </div>
        </form>
      )}
    </Lobby>
  );
};

export default ForgotPasswordForm;
