import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../dashboard/styles/dashboard-core-shared.sass';
import styles from '../styles/lobby.module.sass';

const Lobby = ({
  id, header, subHeader, children,
}) => (
  <div id={id} className={styles.lobbyWrapper}>
    <div className={classnames(styles.leftPane, 'scrollable')}>
      <div className={styles.appWrapper}>
        <div className={styles.logo}>
          <img src="/static/img/a-logomark-white.svg" alt="" />
        </div>
        <h1 className={styles.header}>{header}</h1>
        <h2 id="subheader" className={styles.subHeader}>{subHeader}</h2>
        <div className={styles.appContent}>
          {children}
        </div>
      </div>

    </div>
    <div className={styles.rightPane}>
      <div className={styles.bluePart} />

      <img src="/static/img/askem-lobby-showcase.svg" alt="" />

    </div>
  </div>
);

Lobby.propTypes = {
  id: PropTypes.string,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

Lobby.styles = styles;

export default Lobby;
