/* eslint-disable react/jsx-filename-extension */
import 'es6-symbol';
import 'url-search-params-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../common/js/polyfills/form-data';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
} from 'react-router-dom';

import axios from 'axios';
import Lobby from './components/lobby';

import ForgotPasswordForm from './components/forgot-password-form';
import GoogleSignInButton from './components/google-sign-in-button';


import RNSButton from '../common/js/components/rns-button';

const { styles: lobbyStyles } = Lobby;

const getInitialError = (query) => {
  let error = null;
  switch (query?.get('error')) {
  case 'google':
    error = 'Google sign-in failed.';
    break;
  case 'sso':
    error = 'Single sign-on (SSO) failed.';
    break;
  case 'account':
    error = 'You don\'t seem to have access to any organization.';
    break;
  default:
  }
  return error;
};

const LoginForm = () => {
  const location = useLocation();
  const url = location.search;
  const query = new URLSearchParams(location.search);

  const initialError = getInitialError(query);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(initialError);

  const fromRns = url?.match('from_rns');

  const onSubmit = (e) => {
    e?.preventDefault();
    setSubmitted(true);
  };

  useEffect(() => {
    if (submitted) {
      axios.post('/login', { username, password })
        .then(() => {
          const urlParams = new URLSearchParams(window.location.search);
          window.location.href = urlParams.get('redirect') || '/';
        }).catch(() => {
          setError('Incorrect username or password.');
          setSubmitted(false);
        });
    }
  }, [submitted]);

  return (
    <Lobby id="login-page" header="Welcome back to Askem," subHeader="enter your details to log in!">
      <>
        {
          fromRns && (
            <div className="from-rns-message">
              <i className="fa fa-info-circle" />
              <div>
                <p>Your organization has been migrated from Askem Classic to Askem.</p>
                <p>Same credentials can be used here.</p>
                <p>Please log in!</p>
              </div>
            </div>
          )
        }
        <form onSubmit={onSubmit}>
          <input
            type="email"
            name="username"
            placeholder="e-mail or username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            autoFocus
          />
          <input
            type="password"
            name="password"
            placeholder="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
          { error && (
            <span className={lobbyStyles.errorMessage} id="login-fail-message">
              {error}
            </span>
          )}
          <Link
            id="show-reset"
            to="/forgot"
            className={lobbyStyles.forgotPasswordToggle}
          >
            Forgot your password?
          </Link>
          <RNSButton
            id="login"
            processing={submitted}
            type="submit"
            prompt="Log in"
            handleClick={onSubmit}
          />
          <div className={lobbyStyles.orDivider}>
            <span>- or - </span>
          </div>
          <GoogleSignInButton />
        </form>
      </>
    </Lobby>
  );
};

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/forgot" component={ForgotPasswordForm} />
      <Route path="/" component={LoginForm} />
      <Route path="/login/:error?" component={LoginForm} />
    </Switch>
  </Router>,
  document.getElementById('root'),
);
