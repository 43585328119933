import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import '../../sass/_rns_button.sass';

import coreStyles from './rns-button.core.module.sass';

const styles = {
  core: coreStyles,
};

const onEnter = (event, next) => {
  if (event.key === 'Enter') next();
};

const RNSButton = ({
  id,
  className,
  dataTestId,
  prompt,
  disabled,
  processing,
  handleClick,
  classes,
  type,
  style,
  theme,
  primary,
}) => {
  const {
    idleClassName = styles[theme]?.rnsButton || classes?.idle || 'rns-button',
    processingClassName = styles[theme]?.processing || classes?.processing || 'rns-button-processing',
  } = classes;

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-testid={dataTestId}
      id={id}
      className={classnames(
        idleClassName,
        { [processingClassName]: processing },
        { [styles[theme]?.primary]: primary },
        className,
      )}
      disabled={disabled || processing}
      type={type}
      onClick={handleClick}
      onKeyPress={event => { if (handleClick) onEnter(event, handleClick); }}
      tabIndex="0"
      style={style}
    >
      <span className="loading" />
      {prompt}
    </button>
  );
};

RNSButton.defaultProps = {
  processing: false,
  disabled: false,
  classes: {},
  type: 'button',
  style: {},
  theme: undefined,
};

RNSButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  prompt: PropTypes.string.isRequired,
  processing: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
  classes: PropTypes.objectOf(PropTypes.shape({})),
  type: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.shape({})),
  theme: PropTypes.string,
  primary: PropTypes.bool,
};

export default RNSButton;
